<template>
    <div class="p-col-12 p-lg-12 p-mb-lg-0 p-py-0 p-px-0 p-d-flex p-flex-column allHeight">
        <h4 class="p-d-inline p-mb-0">{{ periodicityId ? 'Редактирование периодичности' : 'Создание периодичности' }}</h4>
        <TabMenu v-if="periodicityId" :model="tabs" />
        <router-view :loading="loading" @loadingChange="loadingChange"></router-view>
    </div>
</template>

<script>
export default {
    name: 'newPeriodicity',
    props: {
        loading: {
            type: Boolean,
            require: false,
        },
    },
    emits: ['loadingChange'],
    methods: {
        loadingChange(payload) {
            this.$emit('loadingChange', payload);
        },
    },
    computed: {
        tabs() {
            return this.periodicityId
                ? [
                      {
                          label: 'Основное',
                          to: `/control/periodicity/${this.periodicityId}/mainparametrs`,
                      },
                      {
                          label: 'Периоды',
                          to: `/control/periodicity/${this.periodicityId}/periods`,
                      },
                  ]
                : [];
        },
        periodicityId() {
            return this.$route.params.periodicityId || null;
        },
    },
};
</script>

<style lang='scss' scoped>
.allHeight {
    flex-basis: 100%;
    box-shadow: none;
}
::v-deep .p-tabmenu {
    .p-tabmenu-nav .p-tabmenuitem {
        .p-menuitem-link:nth-child(1) {
            padding-left: 0.5rem;
        }
    }
}
</style>


